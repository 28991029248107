import About from "./about";
import Contact from "./contact";

export default function Home() {
  return (
    <>
      <header className="masthead">
        <div className="container px-4 px-lg-5 d-flex h-100 align-items-end justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <h1 className="mx-auto my-0 text-uppercase">SKYTEX</h1>
              <h2 className="text-white-50 mb-sm-5 text-uppercase fw-bold mx-auto">
                Unmanned Aerial Solutions
              </h2>
            </div>
          </div>
        </div>
      </header>
      <About />
      <Contact />
    </>
  );
}
