import { useEffect, useState } from "react";
import "./css/styles.css";

export default function Products() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [products, setProducts] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://theskytex.com/api/products", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password }),
    })
      .then((res) => res.text())
      .then((data) => {
        if (data === "Not Authenticated") {
          alert("Not Authenticated");
        } else {
          setIsAuthenticated(true);
          const dropMenu = document.getElementById("dropMenu");
          dropMenu.style.removeProperty("display");
          setProducts(data)
        }
      });
  };

  useEffect(() => {
    document.getElementById("dropMenu").style.display = "none";
    document.getElementById("mainNav").style.borderBottom =
      "1px solid #00000014";
    document.getElementById("mainNav").style.backgroundColor = "white";
    document.querySelectorAll(".nav-link").forEach((link) => {
      link.style.color = "black";
    });
  }, []);

  if (isAuthenticated) {
    return <div dangerouslySetInnerHTML={{ __html: products }}></div>;
  } else {
    return (
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-6">
            <form onSubmit={handleSubmit} className="form">
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-dark">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
