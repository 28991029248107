import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoWhite from "./assets/img/logo.png";
import LogoBlack from "./assets/img/logo-black.png";

export default function Header() {
  const { pathname, hash } = useLocation();
  const [Logo, setLogo] = useState(LogoWhite);
  useEffect(() => {
    if (pathname === "/products") {
      document
        .getElementById("navbarDropdown")
        .setAttribute("data-bs-toggle", "dropdown");
      setLogo(LogoBlack);
    }
    if (pathname === "/careers") {
      setLogo(LogoBlack);
    }
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, [pathname, hash]);

  useEffect(() => {
    const navbar = document.getElementById("mainNav");
    if (navbar) {
      const navbarShrink = () => {
        if (window.scrollY > 100) {
          navbar.classList.add("navbar-shrink");
        } else {
          navbar.classList.remove("navbar-shrink");
        }
      };
      navbarShrink();
      document.addEventListener("scroll", navbarShrink);
    }
  }, []);
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top"
        id="mainNav"
      >
        <div className="container px-4 px-lg-5">
          <a className="navbar-brand" href="/">
            <img
              loading="lazy"
              style={{ width: "15%" }}
              className="navbar-img"
              src={Logo}
              alt="..."
            />
          </a>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/#about">
                  About
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/products"
                  id="navbarDropdown"
                  //   data-bs-toggle="dropdown"
                >
                  Products
                </Link>
                <ul id="dropMenu" className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#skybuoy">
                      Multirotors &raquo;
                    </a>
                    <ul className="submenu dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#skybuoy">
                          Skybuoy
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#skycrane">
                          Skycrane
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#skycranepro">
                          Skycrane Pro
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#skywatcher">
                          Skywatcher
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#skypatrol">
                      Fixed Wings &raquo;
                    </a>
                    <ul className="submenu dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#skypatrol">
                          Skypatrol
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#skypigeon">
                      Fixed Wing VTOL &raquo;
                    </a>
                    <ul className="submenu dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#skypigeon">
                          Skypigeon
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#skypigeonxs">
                          Skypigeon XS
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#sp-4t2">
                          SP-4T2
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#contact">
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/careers">
                  Careers
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
