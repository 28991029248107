export default function About() {
  return (
    <>
      <section className="about-section text-center" id="about">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8">
              <h2 className="text-white mb-3">ABOUT US</h2>
              <p className="text-white-50">
                SkyteX is a Research and Development UAV company which envisions
                to develop state of the art cutting edge technologies for
                autonomous/combat UAVs. We also develop drone affiliated systems
                in static and dynamic aerial surveillance space for distinct
                defence and civilian applications.We are a group of seasoned
                entrepreneurs, engineers and developers aiming to challenge the
                current drone ecosystem of ISR, transportation and mapping.
                <br />
                <br />
                Our in-house capabilities include versatile manufacturing,
                Dedicated Avionics & AI development and software/hardware for
                various BVLOS applications. SkyteX has access to private
                airstrips/flight facility to ensure the timely execution and
                certification of manufactured UAVs.
              </p>
              <p className="text-white fs-5">Build.. Test.. Deploy</p>
            </div>
          </div>
          <img
            loading="lazy"
            className="img-fluid"
            src="assets/img/about.webp"
            alt="..."
          />
        </div>
      </section>
    </>
  );
}
