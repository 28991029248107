import { useEffect } from "react";

export default function Careers() {
  useEffect(() => {
    document.getElementById("mainNav").style.backgroundColor = "white";
    document.getElementById("mainNav").style.borderBottom = "1px solid #00000014";
    document.querySelectorAll(".nav-link").forEach((link) => {
      link.style.color = "black";
    });
  }, []);
  return (
    <>
      <section
        className="careers-section bg-white d-flex align-items-center
      "
        style={{ height: "100vh" }}
        id="careers"
      >
        <div className="container px-4 px-lg-4">
          <div className="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div className="col-lg-6">
              <h4 className="text-black text-center">CURRENT OPENINGS</h4>
            </div>
          </div>
          <div className="row gx-0 mb-4 mb-lg-5 align-items-center">
            <iframe
              id="idIframe"
              // onLoad={() => iframeLoaded()}
              src="https://skytex.keka.com/careers/api/embedjobs/1e3dec49-842f-4274-8d76-5fa491ad8765"
              frameborder="0"
              height="500"
              width="100%"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
}
