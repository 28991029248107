import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./header";
import Home from "./home";
import About from "./about";
import Products from "./products";
import Footer from "./footer";
import Careers from "./careers";
import Contact from "./contact";
import NotFound from "./NotFound";
import ScrollToTop from "./ScrollToTop";

export default function AppRoutes() {
  return (
    <Router>
      <>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </>
    </Router>
  );
}
