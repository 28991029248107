export default function Footer() {
  return (
    <>
      
      {/* Footer */}
      <footer className="footer bg-black small text-center text-white-50">
        <div className="container px-4 px-lg-5">
          Copyright &copy; Skytex Unmanned Aerial Solutions 2023
        </div>
      </footer>
    </>
  );
}
